import React from "react";
import { string, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../../hooks/use-start-motion";
import quotes from "../../../../images/svg/quotes.svg";

const variants = {
  hidden: { opacity: 0, y: 150 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const SecondaryHeroTitleTwoCols = ({
  className,
  title,
  quote,
  personName,
  personPosition,
  imageName,
  mobileImageName,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`
        max-w-1440 w-full mx-auto px-0 lg:px-10
        ${className}
    `}
      ref={ref}
      data-cy="secondary-hero-title-with-quotes"
    >
      <div
        className={`
        bg-black rounded-large px-4 lg:px-32 pt-20 lg:pt-36
        grid grid-cols-1 xl:grid-cols-2 xl:gap-44
        pb-80
      `}
      >
        <motion.div
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={fadeInVariants}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          <Heading
            level={2}
            injectionType={1}
            withBlueSymbol
            className="text-white"
          >
            {title}
          </Heading>
        </motion.div>

        <motion.div
          className="my-8 xl:my-0"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={fadeInVariants}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          <img className="transform scale-50 -ml-7" src={quotes} alt="quotes" />
          <blockquote className="text-black-600 text-p">{quote}</blockquote>
          <cite className="text-white text-p font-bold mt-8 not-italic	">
            {personName}
          </cite>
          <div className="text-black-600 text-p">{personPosition}</div>
        </motion.div>
      </div>

      <motion.div
        className="rounded-large max-w-1120 mx-auto -mt-72 md:-mt-64 xl:-mt-56 px-4 md:px-10 xl:px-0"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.8, duration: 0.8 }}
      >
        {mobileImageName && (
          <AppetizeImage
            className="rounded-large md:hidden image-mobile"
            imageName={mobileImageName}
          />
        )}
        <AppetizeImage
          className="rounded-large hidden md:block image-desktop"
          imageName={imageName}
        />
      </motion.div>
    </section>
  );
};

SecondaryHeroTitleTwoCols.propTypes = {
  className: string,
  title: string.isRequired,
  quote: string.isRequired,
  personName: string.isRequired,
  personPosition: string.isRequired,
  imageName: string.isRequired,
  mobileImageName: string.isRequired,
  animations: bool,
};

SecondaryHeroTitleTwoCols.defaultProps = {
  className: "",
  mobileImageName: null,
  animations: true,
};

export default SecondaryHeroTitleTwoCols;
